import { render, staticRenderFns } from "./BuyFormStep1.vue?vue&type=template&id=4d4b888f&"
import script from "./BuyFormStep1.vue?vue&type=script&lang=js&"
export * from "./BuyFormStep1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports