<template>
  <setting-layout :title="'Profile'" :activeSettingMenu="1" :active="5">
    <div class="card-body">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6" style="display: none">
          <div class="card h-unset">
            <div class="card-header">
              <h4 class="card-title">User Profile</h4>
            </div>
            <div class="card-body">
              <form action="#" @submit.prevent="">
                <div class="row g-3">
                  <div class="col-xxl-12">
                    <label class="form-label">Your Name</label>
                    <input type="text" class="form-control" placeholder="Name" />
                  </div>
                  <div class="col-xxl-12">
                    <div class="d-flex align-items-center">
                      <img class="me-3 rounded-circle me-0 me-sm-3" src="../../../assets/images/profile/2.png" width="55" height="55" alt="" />
                      <div class="media-body">
                        <h4 class="mb-0">{{ username }}</h4>
                        <p class="mb-0">Max file size is 20mb</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-12">
                    <div class="form-file">
                      <input type="file" class="form-file-input" id="customFile1" />
                      <label class="form-file-label" for="customFile1">
                        <span class="form-file-text">Choose file...</span>
                        <span class="form-file-button">Browse</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-xxl-12">
                    <button class="btn btn-success waves-effect">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="col-xxl-6 col-xl-6 col-lg-6" style="display: none">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">User Profile</h4>
            </div>
            <div class="card-body">
              <form action="#" @submit.prevent="">
                <div class="row g-3">
                  <div class="col-xxl-12">
                    <label class="form-label">New Email</label>
                    <input type="email" class="form-control" placeholder="Email" />
                  </div>
                  <div class="col-xxl-12">
                    <label class="form-label">New Password</label>
                    <input type="password" class="form-control" placeholder="**********" />
                    <small class="mt-2 mb-0 d-block">Enable two factor authencation on the security page </small>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-success waves-effect">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="col-xxl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Personal Information</h4>
              <h4 class="card-title">
                Verification Status:&nbsp;
                <span v-bind:class="{ 'text-error': verification == 'No Verified', 'text-warning': verification == 'Pending', 'text-success': verification == 'Verified' }">
                  {{ verification }}
                  <i v-show="verification == 'No Verified'" class="icofont-warning"></i>
                </span>
              </h4>
            </div>
            <div class="card-body">
              <form method="post" @submit.prevent="sendVerifData" name="myform" class="personal_validate">
                <div class="row g-4">
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Your Full Name</label>
                    <input type="text" class="form-control" placeholder="Fill your full name" name="fullname" v-model="form.fullname" :readonly="readonly" required />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Email</label>
                    <input type="email" class="form-control" placeholder="Fill your email" name="email" v-model="form.email" :readonly="readonly" required />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Date of birth</label>
                    <input
                      type="date"
                      class="form-control hasDatepicker"
                      placeholder="10-10-2020"
                      id="datepicker"
                      autocomplete="off"
                      name="dob"
                      v-model="form.birthdate"
                      :readonly="readonly"
                      required
                    />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Your gender</label>
                    <select class="form-select" name="gender" v-model="form.gender" :disabled="readonly" required>
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>

                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Residential Address</label>
                    <input type="text" class="form-control" placeholder="Fill your adress" name="address" v-model="form.address" :readonly="readonly" equired />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">City</label>
                    <input type="text" class="form-control" placeholder="Fill your city" name="city" v-model="form.city" :readonly="readonly" required />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Postal Code</label>
                    <input type="text" class="form-control" placeholder="Fill your postal code" name="postal" v-model="form.postal_code" :readonly="readonly" required />
                  </div>

                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <label class="form-label">Country</label>
                    <select class="form-select" name="country" v-model="form.country" :disabled="readonly" required>
                      <option value="">Select</option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Åland Islands">Åland Islands</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                      <option value="Botswana">Botswana</option>
                      <option value="Bouvet Island">Bouvet Island</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                      <option value="Brunei Darussalam">Brunei Darussalam</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">Central African Republic</option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote D'ivoire">Cote D'ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">Dominican Republic</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">Equatorial Guinea</option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Territories">French Southern Territories</option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-bissau">Guinea-bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                      <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                      <option value="Korea, Republic of">Korea, Republic of</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macao">Macao</option>
                      <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                      <option value="Moldova, Republic of">Moldova, Republic of</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="Netherlands Antilles">Netherlands Antilles</option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russian Federation">Russian Federation</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Helena">Saint Helena</option>
                      <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                      <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                      <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-leste">Timor-leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">United Arab Emirates</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Viet Nam">Viet Nam</option>
                      <option value="Virgin Islands, British">Virgin Islands, British</option>
                      <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                      <option value="Wallis and Futuna">Wallis and Futuna</option>
                      <option value="Western Sahara">Western Sahara</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabweoption&gt;</option>
                    </select>
                  </div>

                  <div class="col-xxl-4 col-xl-6 col-lg-6">
                    <label class="form-label">Document type</label>
                    <select class="form-select" name="doc_type" v-model="form.doc_type" :disabled="readonly" required>
                      <option value="">Select</option>
                      <option value="Passport">Passport</option>
                      <option value="ID Card">ID Card</option>
                      <option value="Driver License">Driver License</option>
                    </select>
                  </div>

                  <div class="col-xxl-4 col-xl-6 col-lg-6">
                    <label class="form-label">Document number</label>
                    <input type="text" class="form-control" placeholder="Document number" name="doc_num" v-model="form.doc_num" :readonly="readonly" required />
                  </div>

                  <div class="col-xxl-4 col-xl-6 col-lg-6">
                    <label class="form-label">Document expiry date</label>
                    <input type="date" class="form-control" placeholder="Document expiry date" name="doc_expire" v-model="form.doc_exp" :readonly="readonly" />
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label">Upload your document (Passport or ID card)</label>
                    <div class="form-file">
                      <input type="file" class="form-file-input" id="customFile" @change="onFileChange" :disabled="readonly" required />
                      <label class="form-file-label" for="customFile">
                        <span class="form-file-text">{{ fileInputLabel }}</span>
                        <span class="form-file-button">Browse</span>
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label">Upload your selfie with document</label>
                    <div class="form-file">
                      <input type="file" class="form-file-input" id="customFileSelfie" @change="onFileChange1" :disabled="readonly" required />
                      <label class="form-file-label" for="customFileSelfie">
                        <span class="form-file-text">{{ fileInputLabel1 }}</span>
                        <span class="form-file-button">Browse</span>
                      </label>
                    </div>
                  </div>

                  <div class="col-12">
                    <br>
                    <button type="submit" class="btn btn-success ps-5 pe-5 waves-effect" v-show="verification == 'No Verified'">Submit verification</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="modalShowSuccess" id="Success" hide-footer>
      <template #modal-header="{ close }">
        <h5 class="modal-title"></h5>
        <b-button class="btn-close" data-dismiss="modal" aria-label="Close" @click="close()"> </b-button>
      </template>
      <template>
        <div class="auth-form">
          <form @submit.prevent="" class="identity-upload">
            <div class="identity-content">
              <span class="icon"><i class="icofont-check"></i></span>
              <h4>Verification request successfully sent</h4>
              <p>Please wait while our team processes the data</p>
            </div>
          </form>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="modalShowError" hide-footer>
      <template #modal-header="{ close }">
        <h5 class="modal-title"></h5>
        <b-button class="btn-close" data-dismiss="modal" aria-label="Close" @click="close()"> </b-button>
      </template>
      <template>
        <div class="auth-form">
          <form @submit.prevent="" class="identity-upload">
            <div class="identity-content">
              <span class="icon icon-error"><i class="icofont-error"></i></span>
              <h4>Something goes wrong</h4>
              <p>Please try again</p>
            </div>
          </form>
        </div>
      </template>
    </b-modal>
  </setting-layout>
</template>

<script>
import SettingLayout from '../../../components/qCash/settings/SettingLayout.vue'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import db from '../../../initFirebase'

export default {
  components: { SettingLayout },
  data() {
    return {
      form: {
        fullname: '',
        email: '',
        birthdate: '',
        gender: '',
        address: '',
        city: '',
        postal_code: '',
        country: '',
        doc_type: '',
        doc_num: '',
        doc_exp: ''
      },
      doc_img: null,
      username: '',
      email: '',
      reg_date: '',
      verification: '',
      modalShowSuccess: false,
      modalShowError: false,
      documentFile: null,
      selfieFile: null,
      fileInputLabel: 'Choose file...',
      fileInputLabel1: 'Choose file...',
      readonly: false
    }
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      let file = files[0]

      this.documentFile = file
      this.fileInputLabel = file.name

      //console.log(file.name)
    },
    onFileChange1(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      let file = files[0]

      this.selfieFile = file
      this.fileInputLabel1 = file.name

      //console.log(file.name)
    },
    sendVerifData() {
      //console.log(this.form)

      if (this.documentFile && this.selfieFile) {
        this.uploadFile(this.documentFile)
        this.uploadFile(this.selfieFile)
      } else {
        this.modalShowError = true
        return
      }

      db.collection('users')
        .doc(firebase.auth().currentUser.uid)
        .update({
          verifData: this.form,
          verification: 'Pending'
        })
        .then(() => {
          this.readonly = true
          this.modalShowSuccess = true
        })
    },
    uploadFile(file) {
      firebase
        .storage()
        .ref(`${firebase.auth().currentUser.uid}/${file.name}`)
        .put(file)
        .then(() => {
          //console.log('File uploaded!')
          //console.log(file)
          //console.log(snapshot)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  created() {
    db.collection('users')
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot((doc) => {
        this.username = doc.data()?.name
        this.email = doc.data()?.email

        this.verification = doc.data().verification
        this.reg_date = doc.data().reg_date

        if (this.verification == 'Verified' || this.verification == 'Pending') {
          this.readonly = true
        } else {
          this.readonly = false
        }

        if (doc.data().verifData) {
          this.form.fullname = doc.data().verifData.fullname
          this.form.email = doc.data().verifData.email
          this.form.birthdate = doc.data().verifData.birthdate
          this.form.gender = doc.data().verifData.gender
          this.form.address = doc.data().verifData.address
          this.form.city = doc.data().verifData.city
          this.form.postal_code = doc.data().verifData.postal_code
          this.form.country = doc.data().verifData.country
          this.form.doc_type = doc.data().verifData.doc_type
          this.form.doc_num = doc.data().verifData.doc_num
          this.form.doc_exp = doc.data().verifData.doc_exp
        }
      })
  }
}
</script>
