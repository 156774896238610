import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import Fragment from "vue-fragment";
import App from "./App.vue";
import "./assets/css/custom.css";
import "./assets/css/style.css";
import router from "./router.js";
import store from "./store";

import "firebase/compat/analytics";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';

Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true;

// Vue Toasts
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// firebase
import "./initFirebase";

// Initialize Firebase Analytics
if (typeof window !== "undefined") {
  const fb = firebase.analytics();
  console.log(fb)
}

Vue.use(Fragment.Plugin);
Vue.use(VueClipboard);
Vue.use(BootstrapVue);
Vue.use(PerfectScrollbar);
Vue.use(Toast, {
  maxToasts: 1,
});

let app;

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
